<template>
    <div class="into">
        <div class="topTitle" >
            <icon-svg name="invstu" style="color:#5cd9e8;position: absolute; left: 35%;top: 6%;font-size: 20px"></icon-svg>
            <span class="fs-xl text mx-2" style="font-size: 16px;margin-left: 195px">产品评论走势情况</span>
            <span class="newTech" @click="$router.push({ name: 'recommend-result-word2vec' })">>>新的监测</span>
        </div>
        <div class="content">
            <div id="area-chart" style="width: 140%;height:100%;margin-left: -80px;margin-top: -25px"></div>
        </div>
    </div>
</template>

<script>
    const echarts = require('echarts')
    export default {
        components: {
        },

        data() {
            return {
                number: 0,
                timer: null
            }
        },
        mounted() {
            this.draw()
        },
        props: {
            linexKey: {
                type: Array,
                required: true,
                default: null
            },
            linePos: {
                type: Array,
                required: true,
                default: null
            },
            lineNeg: {
                type: Array,
                required: true,
                default: null
            }
        },
        methods: {
            draw() {
                // 基于准备好的dom，初始化echarts实例
                this.chart = echarts.init(document.getElementById('area-chart'))
                //  ----------------------------------------------------------------
                let option = {
                    color: ['#80FFA5', '#FF0087',],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data: ['积极评论', '消极评论'],
                        textStyle:{//图例文字的样式
                            color:'#ccc'
                        },
                        y: '5%'
                    },
                    toolbox: {
                        feature: {
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: this.linexKey,
                            axisLine:{
                                lineStyle: {
                                    color:'#ffffff'
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLine:{
                                lineStyle: {
                                    color:'#ffffff'
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '积极评论',
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgb(128, 255, 165)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(1, 191, 236)'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: this.linePos
                        },
                        {
                            name: '消极评论',
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgb(0, 221, 255)'
                                    },
                                    {
                                        offset: 1,
                                        color: '#FF0087'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: this.lineNeg
                        }
                    ]
                }
                this.chart.setOption(option)
                this.timer = setInterval(() => {
                    this.chart.dispatchAction({
                        type: 'showTip',
                        seriesIndex: 0,
                        dataIndex: this.number
                    })

                    this.number++

                    if (this.number > this.linexKey.length) {
                        this.number = 0
                    }
                }, 1000)
            },
            beforeDestroy() {
                clearInterval(this.timer)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .into{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .topTitle{
            height: 10%;
            padding-top: 15px;
        }
        .content{
            flex: 2;
        }
    }
    .newTech {
        font-size: 14px;
        margin-left: 85px;
        color: #00ffd0;
        cursor: pointer;
    }
</style>
