<template>
    <div class="into">
        <div class="topTitle" >
            <icon-svg name="word" style="color:#5cd9e8;position: absolute; left: 35%;top: 6%;font-size: 20px"></icon-svg>
            <span class="fs-xl text mx-2" style="font-size: 16px">评论关键词</span>
        </div>
        <div class="content">
            <dv-capsule-chart :config="config" style="width: 140%;height: 110%;margin-left: -70px" />
        </div>

    </div>
</template>

<script>
    const echarts = require('echarts')
    export default {
        props: {
            config: {
                type: Object,
                required: true,
                default: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .into{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .topTitle{
            height: 10%;
            padding-top: 15px;
        }
        .content{
            flex: 2;
        }
    }
</style>
