<template>
	<div style="height:100%">

		<div id="alarm" style="height:calc(80% - 10px);"></div>
	</div>
</template>

<script>
	const echarts = require('echarts')
	export default {
		components: {
		},
		props: {
			recommendLabel: {
				required: true,
				type: Array,
				default: null
			}
		},
		data() {
			return {
				myChart: null,
				cards: [],
				option: {
					grid: {
						show: false,
						top: 10,
						bottom: 10,
					},
					xAxis: [{
						gridIndex: 0,
						type: 'value',
						show: false, //是否显示坐标轴
						min: 0,//坐标轴刻度最小值
						max: 100,//坐标轴刻度最大值
						nameLocation: 'middle', //坐标轴名称显示的位置
						nameGap: 5, //坐标轴名称与轴线之间的距离。
					}, ],
					yAxis: [{
						gridIndex: 0,
						min: 0,
						show: false,
						max: 100,
						nameLocation: 'middle',
						nameGap: 30,
					}, ],
					series: [{
						type: 'effectScatter',
						hoverAnimation: true,
						label: {
							normal: {
								show: true,
								formatter: '{b}',
								color: '#fff',
								textStyle: {
									fontSize: '20',
								},
							},
						},
						itemStyle: {
							normal: {
								color: '#00acea',
							},
						},
						data: [],
					}, ],
				},
				datalist: [{
					offset: [5, 50],
					symbolSize: 60,
					opacity: 0.95,
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: '#29c0fb',
					},
						{
							offset: 1,
							color: '#2dc5b9',
						},
					]),
				},
					{
						offset: [35, 50],
						symbolSize: 60,
						opacity: 0.95,
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							offset: 0,
							color: '#35d17e',
						},
							{
								offset: 1,
								color: '#49ddb2',
							},
						]),
					},
					{
						offset: [65, 50],
						symbolSize: 60,
						opacity: 0.95,
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							offset: 0,
							color: '#e5d273',
						},
							{
								offset: 1,
								color: '#e4a37f',
							},
						]),
					},
					{
						offset: [95, 50],
						symbolSize: 60,
						opacity: 0.95,
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							offset: 0,
							color: '#277aec',
						},
							{
								offset: 1,
								color: '#57c5ec',
							},
						]),
					},
				],
			}
		},
		mounted() {
			this.myChart = echarts.init(document.getElementById('alarm'))

			this.cards = []
			this.recommendLabel.forEach((item, index) => {
				this.cards.push({
					name: '0' + (index+1),
					value: item
				})
			})

			this.$nextTick(() => {
				this.initData()
			})
		},
		methods: {
			initData() {
				var datas = []
				for (var i = 0; i < this.cards.length; i++) {
					var item = this.cards[i]
					var itemToStyle = this.datalist[i]
					datas.push({
						name: item.name + '\n' + item.value,
						value: itemToStyle.offset,
						symbolSize: itemToStyle.symbolSize,
						label: {
							normal: {
								textStyle: {
									fontSize: 18,
									fontWeight: 800,
									lineHeight: 22,
								},
							},
						},
						itemStyle: {
							normal: {
								color: itemToStyle.color,
								opacity: itemToStyle.opacity,
							},
						},
					})
				}
				this.option.series[0].data = datas
				this.myChart.setOption(this.option)
			},
		},
	}
</script>

<style lang="scss" scoped>

</style>
