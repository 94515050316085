<template>
	<div id="center">
		<div class="ctitle">
			<icon-svg name="invstu" style="color:#5cd9e8;position: absolute; left: 35%;top: 6%;font-size: 20px"></icon-svg>
			<span class="fs-xl text mx-2" style="font-size: 16px;margin-left: 195px">产品评论占比情况</span>
			<span class="newTech" @click="$router.push({ name: 'recommend-result-word2vec' })">>>新的监测</span>
		</div>
		<div class="percent">
			<div class="item">
				<center-chart :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
				<span>
					<div style="border-radius:4px; background-color: #3fc0fb;width: 8px; height: 8px;line-height: 8px;margin-top: 10px;"></div>积极评论占比
				</span>
			</div>
			<div class="item">
				<center-chart :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
				<span>
					<div style="border-radius:4px; background-color: #ff9800;width: 8px; height: 8px; line-height: 8px; margin-top: 10px;"></div>消极评论占比
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import CenterChart from './center-chart.vue'
	export default {
		components: {
			CenterChart
		},
		props: {
			pos_percent: {
				required: true,
				type: Number,
				default: 0
			},
			neg_percent: {
				required: true,
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				rate: [{
					id: 'centerRate1',
					tips: this.pos_percent,
					colorData: {
						textStyle: '#3fc0fb',
						series: {
							color: ['#00bcd44a', 'transparent'],
							dataColor: {
								normal: '#03a9f4',
								shadowColor: '#97e2f5'
							}
						}
					}
				},
					{
						id: 'centerRate2',
						tips: this.neg_percent,
						colorData: {
							textStyle: '#67e0e3',
							series: {
								color: ['#faf3a378', 'transparent'],
								dataColor: {
									normal: '#ff9800',
									shadowColor: '#fcebad'
								}
							}
						}
					}
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	#center {
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;

		.ctitle {
			padding: 10px;
			color: whitesmoke;
			height: 10%;

		}

		.percent {
			margin-top: 50px;
			display: flex;
			flex-direction: row;
			 width: 100%;
			 flex: 1;
			.item{
				width: 100%;
			}
			span{
				display: flex;
				justify-content: center;
			};


		}
	}
	.newTech {
		font-size: 14px;
		margin-left: 85px;
		color: #00ffd0;
		cursor: pointer;
	}
</style>
