<template>
    <div style="width: 100%; height:100%">
<!--      <div id="earth"/>-->
      <div class="map">
        <div class="map1"><img src="../../../../../assets/img/tech-analysis/lbx.png" width="100%"></div>
        <div class="map2"><img src="../../../../../assets/img/tech-analysis/jt.png" width="100%"></div>
        <div class="map3"><img src="../../../../../assets/img/tech-analysis/map.png" width="100%"></div>
        <div class="map4" id="map_1"></div>
      </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts/core'
    import 'echarts-gl'

// import { CanvasRenderer } from 'echarts/renderers'
// import { GlobeComponent } from 'echarts-gl/components'
export default {
  name: 'earth-center',
  data () {
    let bgImage = require('../../../../../assets/img/tech-analysis/pageBg1.png')
    let baseTexture = require('../../../../../assets/img/tech-analysis/earth2.jpg')
    return {
      option: {
        // backgroundColor: '#000',
        globe: {
          baseTexture: baseTexture,
          shading: 'lambert',
          environment: bgImage,
          atmosphere: {
            show: false
          },
          light: {
            ambient: {
              intensity: 0.4
            },
            main: {
              intensity: 1.5
            },
            width: 300,
            height: 300
          }
        },
        series: []
      }
    }
  },
  created() {
  },
  mounted () {
    // echarts.use([GlobeComponent, CanvasRenderer])
    // let chartDom = document.getElementById('earth')
    // let myChart = echarts.init(chartDom)
    // this.option && myChart.setOption(this.option)
  },
  methods: {

  }
}
</script>

<style scoped>
  #earth {
    width: 100px;
    height: 100px;
  }
  .map{  position:relative; left: 14%; top: 10%; height: 50%; width:50%;z-index: 9;}
  .map4{ width:60%; height:17rem;  position: relative; left: -50%; top: 4%; margin-top: .2rem; z-index: 5;}
  .map1,.map2,.map3{
    position:absolute;
  }
  .map1{
    width:36rem;
    height: 36rem;
    z-index: 2;
    top:1rem;
    left: 1.2rem;
    -webkit-animation: myfirst2 15s infinite linear;
    transform-origin: center;
  }
  .map2{
    width:33rem;
    height: 33rem;
    top:2rem;
    left: 2rem;
    z-index: 3;
    opacity: 0.2;
    -webkit-animation: myfirst 10s  linear infinite;
    transform-origin: center;
  }
  .map3{
    width:30rem;
    height: 30rem;
    top:4rem;
    left: 4rem;
    z-index: 1;
    -webkit-animation: a3 60s linear infinite;
  }
  @keyframes myfirst
  {
    from {transform: rotate(0deg);}
    to {transform: rotate(-359deg);}
  }
  @keyframes myfirst2
  {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
  }
  @keyframes a3 {
    from { transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }

</style>
