<template>
    <div class="stratop">
        <div class="topTitle" >
            <icon-svg name="value" style="color:#5cd9e8;position: absolute; left: 30%;top: 6%;font-size: 20px"></icon-svg>
            <span class="fs-xl text mx-2" style="font-size: 16px;margin-left: 140px">企业价值力分布</span>
            <span class="newTech" @click="$router.push({ name: 'recommend-result-brand' })">>>新的测算</span>
        </div>
        <div id="rightFirst" style="width: 100%;height: 100%;margin-top: 25px;z-index: 10;"></div>
    </div>
</template>

<script>
    const echarts = require('echarts')
    export default {
        data() {
            return {
                histogramList: [],
                lineList: [],
                radarList: [],
                indicator: [
                    { text: '资本力', max: 100},
                    { text: '市场力', max: 100},
                    { text: '创新力', max: 100},
                    { text: '责任力', max: 100}
                ]
            }
        },
        mounted() {
            this.getNearest()
        },
        methods: {
            getNearest() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.TECH.BRAND.NEAREST),
                    method: 'get',
                    params: this.$http.adornParams({
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        if(data.data){
                            this.histogramList = JSON.parse(data.data.assets) || ['1', '2', '3']
                            this.lineList = JSON.parse(data.data.profit) || ['1', '2', '3']
                            this.radarList.push(data.data.capitalPower) || 100
                            this.radarList.push(data.data.marketPower) || 70
                            this.radarList.push(data.data.innovationPower) || 60
                            this.radarList.push(data.data.responsibilityPower) || 80
                        } else {
                            this.histogramList = ['1', '2', '3']
                            this.lineList = ['1', '2', '3']
                            this.radarList.push(100)
                            this.radarList.push(70)
                            this.radarList.push(60)
                            this.radarList.push(80)
                            this.indicator = [
                                { text: '资本力（样例）', max: 100},
                                { text: '市场力（样例）', max: 100},
                                { text: '创新力（样例）', max: 100},
                                { text: '责任力（样例）', max: 100}
                            ]
                        }

                        this.$nextTick(() => {
                            this.draw()
                        })
                    }
                })
            },
            draw() {
                // 基于准备好的dom，初始化echarts实例
                this.chart = echarts.init(document.getElementById('rightFirst'))
                //  ----------------------------------------------------------------
                let option = {
                    radar: {
                        // shape: 'circle',
                        indicator: this.indicator,
                        name: {
                            textStyle: {
                                color: '#32D38E',
                            },
                        }
                    },

                    series: [
                        {
                            type: 'radar',
                            areaStyle: {
                                color: '#32D38E'
                            },
                            data: [
                                {
                                    value: this.radarList,
                                    name: '价值'
                                }
                            ],
                            label: {
                                normal: {
                                    formatter: function (params) {
                                        return params.value
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: 'rgb(50,211,142)',
                                    },
                                },
                            }
                        }
                    ]
                }
                this.chart.setOption(option)

            }
        },
        destroyed() {
            window.onresize = null
        }
    }
</script>
<style lang="scss" scoped>
    .stratop{
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
        .topTitle{
            height: 10%;
            padding: 10px;
        }
        #rightFirst{
            flex: 2;
        }
    }
    .newTech {
        font-size: 14px;
        margin-left: 65px;
        color: #00ffd0;
        cursor: pointer;
    }
</style>
